<template>
  <v-main>

    <v-container
        id="view-member-container"
        fluid
        tag="section"
        v-if="user"
    >
      <v-card-text v-if="errors.length">
        <v-alert color="error"
                 dark
                 outlined
                 :key="error.id"
                 show variant="danger"
                 v-for="error in errors">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-expansion-panels
        multiple
        light
        hover
        v-model="expandedPanels"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Member Details</v-expansion-panel-header>

            <v-expansion-panel-content>
              <base-material-card
                    icon="mdi-account-details-outline"
                    class="px-5 py-3"
                >

                <v-card-text>

                  <v-row>
                  <v-btn
                      small
                      color="secondary"
                      @click="$router.push(`/member/${user.id}`)"
                  >
                    <v-icon dark>
                      mdi-account-edit-outline
                    </v-icon>
                    Edit
                  </v-btn>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-medium"
                    >
                      Account #:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.accountNumber }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Name:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.names }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Address:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <div v-if="user.address">
                        {{ user.address }} <br/>
                        <span v-if="user.addressLine2">{{ user.addressLine2 }} <br /></span>
                        {{ user.city }}, {{ user.state }} {{ user.postalCode }} <br/>
                        {{ computedCountry.name }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Phone Number:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.phoneNumber }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Email Address:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.email }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Gender:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.gender }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Year of Birth:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.birthYear }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      ID Number:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.idNumber }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      ID Type:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <span v-if="user.idType">{{ user.idType.name }}</span>
                    </v-col>


                  </v-row>
                  <v-row>

                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      ID Issued By:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.idIssuedBy }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      ID Expires:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.idExpires }}
                    </v-col>
                  </v-row>

                   <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Is Married:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <span v-if="user.isMarried">Yes</span>
                      <span v-else>No</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Is Sibling:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <span v-if="user.isSibling">Yes</span>
                      <span v-else>No</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Joined:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ user.joined | formatDate }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Agreed to Bylaws:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      <span v-if="user.agreeToTerms">Yes</span>
                      <span v-else>No</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                        cols="12"
                        sm="3"
                        class="font-weight-bold"
                    >
                      Member Status:
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                      {{ userStatusMap[user.status] }}
                    </v-col>
                  </v-row>

                </v-card-text>

              </base-material-card>

            </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header>Dependents</v-expansion-panel-header>

            <v-expansion-panel-content>

                <base-material-card
                    icon="mdi-family-tree"
                    title="Dependents"
                    class="px-5 py-3"
                >

                  <v-card-text>

                    <v-row>

                    <v-btn
                        small
                        color="secondary"
                        @click="$router.push(`/members/${user.id}/dependent`)"
                    >
                      <v-icon dark>
                        mdi-account-plus-outline
                      </v-icon>
                      Add
                    </v-btn>

                    </v-row>

                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-subtitle-2">Name</th>
                          <th class="text-subtitle-2">Relationship</th>
                          <th class="text-subtitle-2">Birth Year</th>
                          <th class="text-subtitle-2">Gender</th>
                          <th class="text-subtitle-2">City/Town</th>
                          <th class="text-subtitle-2">Country</th>
                          <th class="text-subtitle-2">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="dependent in dependents"
                            :key="dependent.id"
                        >
                          <td>
                            <router-link :to="{ name: 'edit_dependent', params: {memberId: user.id, dependentId: dependent.id} }">
                              {{ dependent.firstName }} {{ dependent.middleName }} {{ dependent.lastName }}
                            </router-link>
                          </td>
                          <td>{{ dependent.relationType.name }}</td>
                          <td>{{ dependent.birthYear }}</td>
                          <td>{{ dependent.gender }}</td>
                          <td>{{ dependent.city }}</td>
                          <td>{{ dependent.country }}</td>
                          <td>{{ dependentStatusMap[dependent.status] }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-card-text>

                </base-material-card>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Claims</v-expansion-panel-header>

            <v-expansion-panel-content>

              <base-material-card
                  icon="mdi-bank-outline"
                  title="Claims"
                  class="px-5 py-3"
              >

                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-subtitle-2">Dependent</th>
                        <th class="text-subtitle-2">Relationship</th>
                        <th class="text-subtitle-2">Date</th>
                        <th class="text-subtitle-2">Cause</th>
                        <th class="text-subtitle-2">Location</th>
                        <th class="text-subtitle-2">Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="claim in claims"
                          :key="claim.id"
                      >
                        <td>
                          <router-link :to="{ name: 'edit_claim', params: {memberId: user.id, claimId: claim.id} }">
                            {{ claim.dependent.firstName }} {{ claim.dependent.middleName }} {{ claim.dependent.lastName }}
                          </router-link>
                        </td>
                        <td>{{ claim.dependent.relationType.name }}</td>
                        <td>{{ claim.incidentDate | formatDate }}</td>
                        <td>{{ claim.cause }}</td>
                        <td>{{ claim.dependent.city }}, {{ claim.dependent.country }}</td>
                        <td>{{ claimStatusMap[claim.status] }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-card-text>

              </base-material-card>
            </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header>Invoices</v-expansion-panel-header>

            <v-expansion-panel-content>

              <base-material-card
                  icon="mdi-text-box-outline"
                  title="Invoices"
                  class="px-5 py-3"
              >
                <v-card-text v-if="invoices">

                  <v-simple-table
                      fixed-header
                  >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="invoice-num text-subtitle-2">Invoice #</th>
                        <th class="bill-to text-subtitle-2">Bill To</th>
                        <th class="invoice-amount text-subtitle-2">Amount</th>
                        <th class="invoice-date text-subtitle-2">Invoice Date</th>
                        <th class="due-date text-subtitle-2">Due Date</th>
                        <th class="invoice-status text-subtitle-2">Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="invoice in invoices"
                          :key="invoice.id"
                      >
                        <td>
                          <router-link :to="{ name: 'view_invoice', params: {invoiceId: invoice.id} }">
                            {{ invoice.id }}
                          </router-link>
                        </td>
                        <td>
                          {{ invoice.user.firstName }} {{ invoice.user.middleName }} {{ invoice.user.lastName }}
                        </td>
                        <td class="text-right">{{ invoice.total | formatCurrency }}</td>
                        <td>{{ invoice.invoiceDate | formatDate }}</td>
                        <td>{{ invoice.dueDate | formatDate }}</td>
                        <td>{{ invoiceStatusMap[invoice.status] }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-card-text>

              </base-material-card>

            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import constants from "@/utils/constants";

export default {
  name: 'CoreViewMemberView',

  created() {
    this.userId = this.$route.params.memberId
    this.fetchMember()
  },

  computed: {
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value
      }
    }
  },

  methods: {
    fetchMember() {
      api
          .fetchUser(this.userId)
          .then(response => {
            this.user = response.data
            this.fetchDependents()
            this.fetchClaims()
            this.fetchInvoices()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchDependents() {
      api
          .fetchDependents(this.user.id)
          .then(response => {
            this.dependents = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchClaims() {
      api
          .fetchUserClaims(this.user.id)
          .then(response => {
            this.claims = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchInvoices() {
      api
          .fetchUserInvoices(this.user.id)
          .then(response => {
            this.invoices = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      userId: null,
      user: {},
      dependents: [],
      claims: [],
      invoices: [],
      expandedPanels: [1, 2, 3, 4],
      userStatusMap: constants.getUserStatusMap(),
      dependentStatusMap: constants.getDependentStatusMap(),
      claimStatusMap: constants.getClaimStatusMap(),
      invoiceStatusMap: constants.getInvoiceStatusMap(),
    }
  }
}
</script>

<style lang="sass">
th.invoice-amount, th.invoice-status
  width: 5%

th.invoice-num, th.invoice-date, th.due-date, th.invoice-amount, th.invoice-status
  width: 10%

</style>
